<template>
  <div>
    <div class="line"></div>
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      @select="handleSelect"
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#ffd04b"
      :router="true"
    >
      <el-menu-item index="/MyClient" v-if="roleId == 3 || roleId == 0"
        >我的客户</el-menu-item
      >
      <!-- <el-submenu index="1">
        <template slot="title">商家列表</template> -->
      <el-menu-item index="/company" class="item2">商家详情</el-menu-item>
      <el-menu-item
        index="/CompanyJoin"
        v-if="roleId == 3 || roleId == 1 || roleId == 'null'"
        >加入商家</el-menu-item
      >
      <el-menu-item
        :index="`/CompanyJoinItem?id=${companyId}`"
        v-if="roleId == 5 || roleId == 2"
        >旗下业务员</el-menu-item
      >
      <!-- </el-submenu> -->
      <el-menu-item index="/orderList"
        ><a target="_blank">订单管理</a></el-menu-item
      >
      <el-menu-item
        :index="`/partner?type=${JSON.stringify(1)}`"
        v-if="roleId == 1 || roleId == 'null' || roleId == undefined"
        ><a target="_blank">公司注册</a></el-menu-item
      >
      <el-menu-item
        :index="`/partner?type=${JSON.stringify('kehu')}`"
        v-if="roleId == 1 || roleId == 'null' || roleId == undefined"
        ><a target="_blank">客户注册</a></el-menu-item
      >
      <el-menu-item
        :index="`/partner?type=${JSON.stringify(3)}`"
        v-if="roleId == 1 || roleId == 'null' || roleId == undefined"
        ><a target="_blank">城市合伙人</a></el-menu-item
      >
      <el-menu-item
        :index="`/partner?type=${JSON.stringify(4)}`"
        v-if="roleId == 1 || roleId == 'null' || roleId == undefined"
        ><a target="_blank">项目合伙人</a></el-menu-item
      >
      <el-menu-item
        :index="`/partner?type=${JSON.stringify(5)}`"
        v-if="roleId == 1 || roleId == 'null' || roleId == undefined"
        ><a target="_blank">服务合伙人</a></el-menu-item
      >
      <el-menu-item
        :index="`/partner?type=${JSON.stringify(6)}`"
        v-if="roleId == 1 || roleId == 'null' || roleId == undefined"
        ><a target="_blank">设计师</a></el-menu-item
      >
      <el-menu-item
        v-if="roleId == 2 || roleId == 5"
        index="/certificate"
        class="certificate"
        >我的公司</el-menu-item
      >
      <!-- <el-menu-item index="/setInfo" class="certificate" v-if="roleId == 1"
        >情报提供</el-menu-item
      > -->
      <el-menu-item index="/setInfoissue" class="certificate"
        >采购信息发布</el-menu-item
      >
      <el-menu-item index="/goodListInfo"  class="goodListInfo"  
      v-if="roleId == 3"
        >购物清单</el-menu-item>

      <el-menu-item index="/information"  class="information"  
        >价格行情</el-menu-item>

      <el-menu-item  class="searchWorker"  v-if="roleId == 2 || roleId == undefined"
        >招工求职
        <span class="searchWorker-worker"  v-if="roleId == undefined || roleId == 2 ">
          
          <div @click="searchWorker">找工作</div>
          <div @click="searchWorkers">找工人</div>
          <div @click="searchTotal">招工求职</div>
        </span>
        </el-menu-item>
        <el-menu-item index="/resume"  class="resume"  
        >厨师直招</el-menu-item>
    </el-menu>

    
    
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeIndex: "1",
      isWorker:false
    };
  },
  // 权限信息
  computed: {
    roleId() {
      return sessionStorage.getItem("roleId");
    },
    companyId() {
      return sessionStorage.getItem("companyId");
    },
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    searchWorker(){
      this.$router.push('searchWorker')
    },
    searchWorkers(){
      this.$router.push('searchWorkers')
    },
    searchTotal(){
      this.$router.push('searchTotal')
    }
  },
};
</script>
<style scoped>
.el-menu.el-menu--horizontal {
  border: 0;
}
.el-menu-demo {
  border-top-left-radius: 20px;
}
.searchWorker{
  position: relative;
}
.searchWorker-worker{
  display: flex;
  position:absolute;
  width: 100px;
  background:rgb(84, 92, 100);
  color: white;
  z-index: 2;
  top: 0px;
  left: 82px;
  display: none;
  text-align: center
}
.searchWorker-worker div:hover{
  color: yellow;
}
.searchWorker:hover .searchWorker-worker{
  display: block;
  
}
</style>