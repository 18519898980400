<template>
  <div @click="userListshow = false">
    <el-card class="box-card" shadow="never">
      <div class="box-card-title">
        <div>
          <el-select
            @change="getbusiness"
            v-model="purchaseType"
            placeholder="请选择采购类型"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
        </el-select>
        </div>
        <div @click="gowallBulletin">
          <span>进入</span><i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <el-table :data="businessList" border style="width: 100%">
        <el-table-column prop="title" label="标题" width="260">
        </el-table-column>
        <el-table-column label="采购类型" width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.purchaseType | purchaseType }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="projectContact" width="110" label="项目联系人">
        </el-table-column>
        <el-table-column prop="address" width="180" label="业务所在地址">
        </el-table-column>
        <el-table-column prop="updateTime" label="更新时间"> </el-table-column>
        <el-table-column prop="applyNum" width="80" label="报名人数">
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="showdialogTableVisible(scope.$index, scope.row)"
              >查看</el-button
            >
            <el-button
              size="mini"
              type="primary"
              @click="postsave(scope.$index, scope.row)"
              >报名</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="el-pagination"
        background
        layout="prev, pager, next"
        :page-size="pageSize"
        :total="total"
        @current-change="currentchange1"
        :hide-on-single-page="true"
      >
      </el-pagination>
      <!-- 分页 -->
    </el-card>
  </div>
</template>

<script>
export default {
  props: ["purchaseType"],
  inject: ["reload"],
  data() {
    return {
      // 业务表列表
      businessList: [],
           options:[
              {
                value: "0",
                label: "查询全部",
              },
            //  {
            //     value: "1",
            //     label: "厨房设备采购",
            //   },
            //   {
            //     value: "2",
            //     label: "厨房服务采购",
            //   },
              {
                value: "3",
                label: "食材采购",
              },
           ],   
           
      value: "", //当前选中的选项
      pageNum: 1,
      pageSize: 5,
      total: 0,
    };
  },
  filters: {
    purchaseType(val) {
      if (val == 3) {
        return "食材采购";
      // } else if (val == 2) {
      //   return "厨房服务采购";
      // } else if (val == 3) {
      //   return "食材采购";
      } else {
        return "未填写";
      }
    },
  },
  methods: {
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 去往情报页
    gowallBulletin() {
      this.$router.push({
        path: "/wallBulletin",
        query: {
          purchaseType: JSON.stringify(this.purchaseType),
        },
      });
    },
    //   获取业务表列表
    async getbusiness() {
      let purchaseType = "";
      if (this.purchaseType == "" || this.purchaseType == 0) {
        purchaseType = "";
      } else {
        purchaseType = `&purchaseType=${this.purchaseType}`;
      }
      let searchtitle = "";
      const res = await this.$http({
        methos: "get",
        url: `front/businessInfo/findAll?pageNum=${this.pageNum}&pageSize=${this.pageSize}${purchaseType}&state=2`,
      });
      this.businessList = [];
      if (res.data.status == 200) {
        this.pageNum = 1;
        res.data.data.list.map((v, k) => {
          this.businessList.push(v);
        });
        this.total = res.data.data.total;
      }
    },
    backbusiness() {
      this.getbusiness();
    },
    showdialogTableVisible(index, row) {
      this.$router.push({
        path: "/wallBulletinDetail",
        query: {
          item: JSON.stringify(row),
        },
      });
    },
    // 报名
    async postsave(index, row) {
      const res = await this.$http({
        method: "post",
        url: "front/businessUser/save",
        data: {
          businessId: row.id,
        },
      });
      if (res.data.status == 200) {
        this.$message({
          showClose: true,
          message: "报名成功",
          type: "success",
        });
      }
    },
    // 查看详细信息
    async lookInfo(index, row) {
      this.$router.push({
        path: "/wallBulletinItem",
        query: {
          item: JSON.stringify(this.userbusinessList[index]),
        },
      });
    },
    // 页面改变时的回调函数
    currentchange1(p) {
      this.pageNum = p;
      this.getbusiness();
    },
  },
  created() {
    this.getbusiness();
  },
};
</script>
<style lang="scss" scoped>
.el-dialog__wrapper {
  & ::v-deep .el-dialog {
    min-width: 1000px;
  }
}

.box-card {
  margin: 0 auto;
  margin-top: 10px;
  //   margin-bottom: 0px;
  width: 99%;
  min-width: 1000px;
  .box-card-title {
    color: #888;
    padding: 10px;
    padding-top: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      span {
        margin-top: -3px;
        cursor: pointer;
      }
      i {
        cursor: pointer;
      }
    }
  }
}
.el-dialog__footer {
  text-align: start;
}
.businessList-header {
  padding: 5px;
  margin-right: 10px;
}
.businessList-header span:nth-child(2) {
  color: #333;
}

.businessList-header-box {
  box-sizing: border-box;
  margin: 0 auto;
  width: 100%;
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #aaa;
  border-radius: 4px;
}
.input-with-select {
  width: 260px;
  margin-left: 20px;
}
.button-box {
  margin-top: 15px;
  display: flex;
  flex-direction: row-reverse;
  padding-right: 40px;
}
.button-item {
  margin-left: 20px;
}
.userList {
  width: 400px;
  height: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -200px;
  margin-top: -150px;
}
.userList-item {
  padding: 5px 5px;
  padding-bottom: 20px;
}
.userList-item:not(:last-child) {
  border-bottom: 1px solid #aaa;
}
.box-card-userList {
  height: 100%;
  overflow: auto;
}
.box-card-userList > div {
  height: 100%;
}
.el-pagination {
  padding: 30px;
  margin-left: 660px;
}
.el-dialog__body > div {
  padding: 3px;
}
</style>