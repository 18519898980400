<template>
  <div class="index">
    <!-- 右侧广告 -->
    <div class="rightdvertising" v-if="Rightadvertising">
      <!-- <i class="el-icon-close" @click="Rightadvertising = false"></i>
      <img src="http://i1.fuimg.com/671541/30e16bf99b20fc10.jpg" alt="" /> -->
    </div>
    <my-header :class="{ show: showheader, hide: !showheader }"></my-header>
    <el-container id="el-container">
      <!-- 头部广告条 -->
      <div class="Headeradvertising" v-if="Headeradvertising">
        <img
          src="https://img10.360buyimg.com/babel/jfs/t1/141438/6/19493/75142/5fe05fa7E6176b6b9/3b5703dee0c3b505.jpg!q70.jpg.webp"
          alt=""
        />
        <i class="el-icon-close" @click="Headeradvertising = false"></i>
      </div>
      <div id="header" height="132px">
        <div class="postion-box">
          <div class="header-box">
            <div class="header-box-left">
              <div @mouseenter="Cityshow = true" @mouseleave="Cityshow = false">
                <div
                  v-if="city"
                  :style="{ position: 'absolute', top: '135px' }"
                >
                  <i class="el-icon-location"></i>
                  <span>{{ city }}</span>
                </div>
                <city-cascade
                  @getCity="getCity"
                  v-if="Cityshow"
                  :style="{ position: 'absolute', top: '160px' }"
                ></city-cascade>
              </div>
              <div>您好，欢迎来到厨盟</div>
              <div class="header-box-login" @click="toLogin" v-if="!userinfo">
                您好，请登录
              </div>
              <div
                class="header-box-login"
                v-else
                v-text="`欢迎您,${userinfo.nickName || userinfo.phone}`"
              ></div>
              <div class="header-box-register" v-if="!this.$store.state.token">
                免费注册
              </div>
              <div class="header-box-list" @click="myOrder">我的订单</div>
              <div class="header-box-list" @click="purchaseOrder">采购订单</div>
              <div
                class="header-box-list"
                @click="$router.push('/browsingHistory')"
              >
                我的足迹
              </div>
              <div
              class="header-box-list"
              @click="IncUpdate"
              v-if="roleId == 2 || roleId == 4"
            >
              查看授权
            </div>
            <div
              class="header-box-list"
              @click="applyFor"
              v-if="roleId == 2 || roleId == 4"
            >
              查看申请
            </div>
            
            </div>
          </div>
          <!-- 搜索框头组件 -->
          <div class="header-search">
            <!-- logo -->
            <div class="avater-logo">
              <router-link to="/index">
                <img class="avater-logo" src="../assets//images/timg.jpg" />
              </router-link>
            </div>
            <!-- logo -->
            <!-- 搜索 -->
            <div class="search-box">
              <div class="search-box-main">
                <div class="search-input">
                  <input v-model="name" class="input" type="text" />
                  <div @click="goGoodList" class="go-search">
                    <img
                      class="search-icon"
                      src="../assets/images/gosearch.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- 搜索结束 -->
            <!-- 购物清单 -->
            <div class="shop-list" @click="shopList">
              <span class="iconfont">&#xe7b7;</span>
              <p>采购清单</p>
              <div class="shop-cara">
                <p class="shopcar-index">{{ this.$store.state.shopnum }}</p>
              </div>
            </div>
            <!-- 购物车 -->
            <div class="shop-car" @click="goCar">
              <span class="iconfont">&#xe7b7;</span>
              <p>购物车</p>
              <div class="shop-cara">
                <p class="shopcar-index">{{ this.$store.state.shopnum }}</p>
              </div>
            </div>

            <!-- 购物车结束 -->
            <div class="talk" @click="$router.push('/chatRoom')">
              <span><i class="el-icon-chat-dot-round"></i>消息中心</span>
            </div>
            <!-- 搜索框头组件结束 -->
          </div>
        </div>
      </div>
      <nav-menu class="nav-menu"></nav-menu>
      <el-main id="el-main">
        <div class="el-main-box" @mouseleave="leave">
          <!-- 分类列表 -->
          <div class="cate-list">
            <div
              class="cate-list-div"
              @mouseenter="hidechildListKey(index)"
              v-for="(item, index) in categoryList"
              :key="index"
            >
              <p>{{ item.name }}</p>
            </div>
          </div>
          <!-- 分类列表弹出框 -->
          <div @mouseleave="leave" class="none-box" v-show="cateboxshow">
            <div
              v-for="(item, index1) in catechildList[catechildListKey]"
              :key="index1"
              class="cate-list-div-span"
              @click="goItem(item, index1)"
            >
              <p class="cate-div-txt">{{ item.name }}</p>
              <img
                class="catego-img-two"
                src="../assets/images/catego.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="right-box">
          <!-- 轮播图 -->
          <div class="swiper-box">
            <el-carousel height="500px">
              <el-carousel-item
                v-for="(item, index3) in swiperList"
                :key="index3"
              >
                <img class="swiper-img" :src="item.img" alt="" />
              </el-carousel-item>
            </el-carousel>
          </div>
          <!-- 右侧登录信息表格 -->
          <div class="login-right-box">
            <!-- 头像框 -->
            <div class="block">
              <el-avatar :size="43" :src="circleUrl"></el-avatar>
              <div class="block-txt">
                <div style="margin-top: 5px">
                  <!-- 登录后 -->
                  <div v-if="userinfo">
                    <router-link to="/">
                      <span
                        class="block-txt-a"
                        v-text="`Hi,${userinfo.nickName || userinfo.phone}`"
                      ></span>
                    </router-link>
                    <div>
                      <span class="block-txt-a" @click="logOut">退出</span>
                      <span class="blcok-txt-b">|</span>
                      <span class="block-txt-a" @click="myCenter"
                        >个人中心</span
                      >
                    </div>
                  </div>
                  <!-- 登陆后 -->
                  <!-- 登陆前 -->
                  <div v-else>
                    <span class="block-txt-title">Hi,欢迎来逛厨盟!</span><br />

                    <span @click.prevent="toLogin">
                      <span class="block-txt-a">登录</span>
                    </span>
                    <span class="blcok-txt-b">|</span>
                    <span class="block-txt-a">注册</span>
                  </div>
                  <!-- 登陆前 -->
                </div>
              </div>
            </div>
            <div class="fest-message">
              <span class="fest-message-txt">积分商城</span>
              <div class="fest-message-more-box">
                <span
                  class="fest-message-more"
                  @click="$router.push('/scoreshop')"
                  >进入</span
                >
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
            <div class="scorecard-box" v-if="userinfo">
              <el-card
                class="scorecard-box-box-card"
                :body-style="{ padding: '5px', background: 'rgba(0,0,0,0.1)' }"
              >
                <div
                  v-for="(v, k) of scorecardList"
                  :key="k"
                  class="text scorecard-box-item"
                  @click="goScorecard(v.id)"
                >
                  <img :src="v.productImg" />
                  <div>
                    <span>{{ v.productName }}</span>
                    <span>{{ v.productMsg }}</span>
                  </div>
                  <span v-text="`积分:${v.needScore}`"></span>
                </div>
              </el-card>
            </div>
            <!-- 厨盟快报 -->
            <div class="fest-message">
              <span class="fest-message-txt">厨盟快报</span>
              <div class="fest-message-more-box">
                <span
                  class="fest-message-more"
                  @click="$router.push('/wallBulletin')"
                  >进入</span>
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
            <div class="scorecard-box">
              <el-card
                v-if="businessList.length"
                class="scorecard-box-box-card"
                :body-style="{ padding: '5px', background: 'rgba(0,0,0,0.1)' }"
              >
                <div
                  class="scorecard-box-box-card-box"
                  v-for="(v, k) of businessList"
                  :key="k"
                >
                  <div>
                    <span>{{ v.state == 0 ? "招募中" : "已完成" }}</span>
                  </div>
                  <div>
                    <span>{{ v.title }}</span>
                  </div>
                </div>
              </el-card>
            </div>
            <!-- 厨盟快报 -->
            <!-- 业务跟进 -->
            <div class="fest-message">
              <span class="fest-message-txt">业务跟进</span>
              <div class="fest-message-more-box">
                <span
                  class="fest-message-more"
                  @click="$router.push('/wallBullentinMybusiness')"
                  >进入</span
                >
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
            <div class="scorecard-box">
              <el-card
                v-if="userbusinessList.length"
                class="scorecard-box-box-card"
                :body-style="{ padding: '5px', background: 'rgba(0,0,0,0.1)' }"
              >
                <div
                  class="scorecard-box-box-card-box"
                  v-for="(v, k) of userbusinessList"
                  :key="k"
                >
                  <div>
                    <span>{{ v.state == 0 ? "招募中" : "已完成" }}</span>
                  </div>
                  <div>
                    <span>{{ v.title }}</span>
                  </div>
                </div>
              </el-card>
            </div>
             <!-- 业务跟进 -->
          </div>
        </div>
        <div class="select-tab">
          <div>
            <div
              v-for="(v, k) of NewcategoryList"
              :key="k"
              @click="hideNewchildListKey(k)"
            >
              <div>
                <p>{{ v.name }}</p>
                <div>
                  <img :src="NewcategoryListImg[k].img1" alt="" />
                  <!-- <img :src="NewcategoryListImg[k].img2" alt="" /> -->
                </div>
              </div>
            </div>
          </div>
          <!-- <div v-if="NewchildListshow">
            <div v-show="NewchildList">
              <el-button
                type="text"
                v-for="(v, k) of NewchildList[NewcatechildListKey]"
                :key="k"
                @click="goItem(v, k)"
                >{{ v.name }}</el-button
              >
            </div>
          </div> -->
        </div>
      </el-main>
      <div class="good">
        <div class="goods-main">
          <div class="goods-main-message">
            <img
              class="goods-main-img"
              src="../assets/images/goodirec.png"
              alt=""
            />
            <div class="goods-main-txt">为你推荐</div>
            <img
              class="goods-main-img"
              src="../assets/images/goodirec.png"
              alt=""
            />
          </div>

          <div class="goods-list">
            <div
              class="goods-list-box"
              @click="goMess(item)"
              v-for="(item, index4) in goodList"
              :key="index4"
            >
              <div>
                <img class="goods-list-box-img" :src="item.mainImage" alt="" />
              </div>
              <div class="goods-message-txt">
                <span class="goods-message-title">{{ item.name }}</span>
                <div class="goods-price">
                  <span class="price-a">￥</span>
                  <span class="price-b" >{{`${item.productParamVos[activeSize].price}`}}</span>
                  <span class="price-a">.00</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 厨盟快报 -->
        <div class="goods-main">
          <div class="goods-main-message">
            <img
              class="goods-main-img"
              src="../assets/images/goodirec.png"
              alt=""
            />
            <div class="goods-main-txt">厨盟快报</div>
            <img
              class="goods-main-img"
              src="../assets/images/goodirec.png"
              alt=""
            />
          </div>

          <div class="goods-list">
            <my-Wall-bulletin
              :purchaseType="'1'"
              :style="{ width: '100%' }"
            ></my-Wall-bulletin>
            <my-Wall-bulletins
              :purchaseType="'2'"
              :style="{ width: '100%' }"
            ></my-Wall-bulletins>
            <my-Wall-bulletinss
              :purchaseType="'3'"
              :style="{ width: '100%' }"
            ></my-Wall-bulletinss>
          </div>
        </div>

        <div class="goods-main">
          <span @click="$router.push('/company')"
            >查看更多<i class="el-icon-arrow-right"></i>
          </span>
          <div class="goods-main-message">
            <img
              class="goods-main-img"
              src="../assets/images/goodirec.png"
              alt=""
            />
            <div class="goods-main-txt">入驻公司</div>
            <img
              class="goods-main-img"
              src="../assets/images/goodirec.png"
              alt=""
            />
          </div>

          <div class="goods-list">
            <div
              class="goods-list-box"
              v-for="(item, index5) in companyList"
              :key="index5"
              @click="gocompany(item)"
            >
              <div>
                <img
                  class="goods-list-box-img"
                  :src="item.companyLogo"
                  alt=""
                />
              </div>
              <div class="goods-message-txt">
                <span class="goods-message-title">{{ item.companyName }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-footer></el-footer>
    </el-container>
  </div>
</template>

<script>
import cityCascade from "../components/cascade/cityCascade";
import navMenu from "../components/navMenu.vue";
import myWallBulletin from "../components/my-wallBulletin";
import myWallBulletins from "../components/my-wallBulletin1";

import myWallBulletinss from "../components/my-wallBulletin2";

import { mapMutations } from "vuex";
export default {
  inject: ["reload"],
  data() {
    return {
      // header显示隐藏
      showheader: false,
      // 右侧广告图的显示隐藏
      Rightadvertising: true,
      // 头部广告条显示隐藏
      Headeradvertising: true,
      // 当前鼠标悬浮列表key
      catechildListKey: Number,
      NewcatechildListKey: Number,
      // 积分商城列表
      scorecardList: {},
      // 用户信息
      userinfo: JSON.parse(sessionStorage.getItem("userinfo")),
      // 默认头像url
      circleUrl:
        "https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=1416799284,860429581&fm=26&gp=0.jpg",
      name: "",
      // 默认查询第一页
      pageNum: 1,
      companyName: "",
      NewcategoryList: [],
      // 分类图
      NewcategoryListImg: [
        {
          img1: "http://q5355.bvimg.com/10063/56871ba33ab079a6.png",
          // img2: "http://i1.fuimg.com/671541/cf5623d598bed973.jpg",
        },

        {
          img1: "http://q5355.bvimg.com/10063/5a26c8894cdc51cb.png",
          // img2: "http://i1.fuimg.com/671541/dd5361190ef91cb2.jpg",
        },
        {
          img1: "http://q5355.bvimg.com/10063/bb474f9ef9604181.png",
          // img2: "http://i1.fuimg.com/671541/b36ce7660c341210.jpg",
        },
        {
          img1: "http://q5355.bvimg.com/10063/a539429390116504.png",
          // img2: "http://i1.fuimg.com/671541/60de91096d817cc1.jpg",
        },
        {
          img1: "http://q5355.bvimg.com/10063/bd01cc487f7466a8.png",
          // img2: "http://i1.fuimg.com/671541/957aa48d550326f2.jpg",
        },
      ],
      categoryList: [],
      NewchildList: [],
      catechildList: [],
      catechildsList: [],
      goodList: [],
      swiperList: [],
      companyList: [],
      messageList: [],
      toGoodlist: [],
      queryInfo: {
        pageSize: 16,
      },
      activeSize: 0,
      cateId: "",
      cateboxshow: false,
      // 厨盟快报列表
      businessList: [],
      // 业务跟进列表
      userbusinessList:[],
      // 当前选择城市
      city: "",
      // 地区选择显示隐藏
      Cityshow: false,
    };
  },
  watch: {
    "$store.state.checked"(val) {
      sessionStorage.setItem("checked", val);
      if (JSON.parse(sessionStorage.getItem("checked"))) {
        this.city = JSON.parse(sessionStorage.getItem("checked"))[1];
      }
    },
  },
  computed: {
    roleId() {
      return sessionStorage.getItem("roleId");
    },
   
  },
    
  methods: {
    // 获取选择城市模块中的信息
    async getCity(val, lable, bool) {
      console.log(val, lable, bool);
      // 隐藏级联选择框
      this.Cityshow = false;
      // 显示状态为选中城市
      this.city = lable[1];
      sessionStorage.setItem("checked", JSON.stringify(lable));
      sessionStorage.setItem("addressProvince", JSON.stringify(val[0]));
      sessionStorage.setItem("addressCity", JSON.stringify(val[1]));
      this.$store.commit("updatechecked", sessionStorage.getItem("checked"));
      // await this.getgoodList();
      location.reload();
    },
    // 去往厨盟快报页
    hideNewchildListKey(k) {
      this.NewcatechildListKey = k;
      let list = this.NewchildList[k];

      this.$router.push({
        path: "/NewchildList",
        query: {
          list: JSON.stringify(list),
        },
      });
    },
    // 去往公司详情页
    gocompany(item) {
      this.$router.push({
        path: "/company",
        query: {
          id: item.id,
        },
      });
    },
    // async getNewchildList(v) {
    //   this.NewchildListshow = true;
    //   this.NewchildList = [];
    //   const res = await this.$http({
    //     method: "get",
    //     url:
    //       "front/category/findTwoLevelClassificationByParentId?parentId=" +
    //       v.id,
    //   });
    //   if (res.data.status == 200) {
    //     this.NewchildList = res.data.data;
    //   }
    //   console.log(this.NewchildList);
    // },
    handleCommand(command) {
      this.$message("click on item " + command);
    },
    // 获取商品分类列表
    async getgategoryList() {
      let category = JSON.parse(sessionStorage.getItem("category"));
      let category1 = JSON.parse(sessionStorage.getItem("category1"));
      if (category && category1) {
        this.categoryList = category;
        this.NewcategoryList = category1;
        this.catechildList = JSON.parse(
          sessionStorage.getItem("catechildList")
        );
        this.NewchildList = JSON.parse(sessionStorage.getItem("NewchildList"));
      } else {
        const res = await this.$http({
          method: "get",
          url: `front/category/getAll`,
        });
        const data = await this.$http({
          method: "get",
          url: `front/category/getAllByParentId?pageSize=${this.queryInfo.pageSize}&parentId=1110`,
        });
        this.categoryList = res.data.data;
        this.NewcategoryList = data.data.data;
        if (res.data.status == 200 && data.data.status == 200) {
          sessionStorage.setItem("category", JSON.stringify(res.data.data));
          sessionStorage.setItem("category1", JSON.stringify(data.data.data));

          res.data.data.map((v, k) => {
            this.catechildList.push(v.childList);
          });
          data.data.data.map((v, k) => {
            this.NewchildList.push(v.childList);
          });
          sessionStorage.setItem(
            "catechildList",
            JSON.stringify(this.catechildList)
          );
          sessionStorage.setItem(
            "NewchildList",
            JSON.stringify(this.NewchildList)
          );
        }
      }
    },
    // 获取厨盟快报信息
    async getbusiness() {
      const res = await this.$http({
        method: "get",
        url: "front/businessInfo/findAll?pageNum=1&pageSize=5&state=0",
      });
      this.businessList = [];
      if (res.data.status == 200) {
        res.data.data.list.map((v, k) => {
          if (v.state == 0) {
            this.businessList.push(v);
          }
        });
      }
    },
    //
    async findBusinessByUserId() {
      const res = await this.$http({
        method: "get",
        url: `front/businessInfo/findBusinessByUserId`,
        params: {
          userId: "",
          pageNum: this.pageNum,
          pageSize: 5,
          sortType: "create_time desc",
        },
      });
      if (res.data.data.list) {
        this.userbusinessList = res.data.data.list;
      }
    },
    // 退出登录
    async logOut() {
      const res = await this.$http({
        method: "post",
        url: "front/user/logout",
      });
      console.log(res);
     
      sessionStorage.setItem("roleId", "null");
      if(res.status == 200){
        this.$message({
          message:"已退出登录",
          type:"error"
        });
          sessionStorage.clear()
          location.reload();
          this.userinfo = "";
          // console.log(this.userinfo)
          // this.$store.commit("setuserinfo", {});
      }
    },
    // 根据子类id查询指定的商品信息
    async goItem(item) {
      console.log(item);
      console.log(this.pageNum);
      const res = await this.$http({
        method: "get",
        url: `front/product/findList?categoryId=${item.id}&pageNum=${this.pageNum}&pageSize=10`,
      });
      // 已获取参数，页面未完成
      console.log(res.data);
      if (res.data.status == 200) {
        this.$router.push({
          path: "/subset",
          query: {
            list: JSON.stringify(res.data.data.list),
            name: JSON.stringify(item.name),
          },
        });
      }
    },
    // 获取轮播图
    async getswiperList() {
      const res = await this.$http({
        method: "get",
        url: "front/slideShow/findSlideShow",
      });
      // console.log(res)
      if (res.data.status == 200) {
        this.swiperList = res.data.data.list;
        
      };
     
     
      
    },
    // 商品列表
    async getgoodList() {
      // let goodList = JSON.parse(sessionStorage.getItem("goodList"));
      // if (goodList) {
      //   this.goodList = goodList;
      // } else {
      const res = await this.$http({
        method: "get",
        url: "front/product/findList",
        params: {
          pageSize: 20,
          province: sessionStorage.getItem("addressProvince"),
          city: sessionStorage.getItem("addressCity"),
        },
      });
      if (res.data.status == 200) {
        // 判断如果当前定位未知商品为空的话重新不传位置信息请求
        if (res.data.data.list.length) {
          this.goodList = res.data.data.list;
          // console.log(this.goodList[0].productParamVos)
          sessionStorage.setItem(
            "goodList",
            JSON.stringify(res.data.data.list)
          );
        } else {
          // 定位地址没有商品重新请求不传地址信息
          const res = await this.$http({
            method: "get",
            url: "front/product/findList",
            params: {
              pageSize: 20,
            },
          });
          if (res.data.status == 200) {
            this.goodList = JSON.parse(sessionStorage.getItem("goodList"));
            sessionStorage.setItem(
              "goodList",
              JSON.stringify(res.data.data.list)
            );
            setTimeout(() => {
              this.goodList = JSON.parse(sessionStorage.getItem("goodList"));
            }, 50);
          }
        }
        // }
      }
    },
    // 搜索商品
    async goGoodList() {
      const res = await this.$http({
        method: "get",
        url: "front/product/findList?name=" + this.name + "&pageSize=15",
      });
      if (res.data.status == 200) {
        this.toGoodlist = res.data.data.list;
        this.$router.push({
          path: "/goodList",
          query: {
            name: JSON.stringify(this.toGoodlist),
            key: JSON.stringify(this.name),
          },
        });
      }
    },
    // 获取公司信息
    async goCompanyList() {
      const res = await this.$http({
        method: "get",
        url: "front/company/findList?pageSize=5",
      });
      if (res.data.status == 200) {
        this.companyList = res.data.data.list;
      }
    },
    // 去往商品详情页
    async goMess(item) {
      sessionStorage.setItem("item", JSON.stringify(item));
      
      const res = await this.$http({
        method: "get",
        url: "front/product/findById?id=" + item.id,
      });
      if (res.data.status == 200) {
        this.messageList = res.data.data;
        this.$router.push({
          path: "/goodMessage",
          query: { name: JSON.stringify(this.messageList) },
        });
      }
    },
    // 获取购物车信息
    async getshopnum() {
      // 如果未登录,不请求
      if (sessionStorage.getItem("roleId") != "null") {
        const res = await this.$http({
          method: "get",
          url: "front/cart/findList?type=1",
        });
        if (res.data.data) {
          this.$store.commit(
            "altershopnum",
            res.data.data.cartProductVoList.length
          );
        }
      }
    },
     // 获取购物车信息
    async getshopnums() {
      // 如果未登录,不请求
      if (sessionStorage.getItem("roleId") != "null") {
        const res = await this.$http({
          method: "get",
          url: "front/cart/findList?type=2",
        });
        if (res.data.data) {
          this.$store.commit(
            "altershopnum",
            res.data.data.cartProductVoList.length
          );
        }
      }
    },
    // 获取用户详情
    // async getUserId() {
    //   const res = await this.$http({
    //     method: "get",
    //     url: "front/user/getUserByToken",
    //   });
    //   if (res.data.data) {
    //     this.$store.commit("setuserinfo", res.data.data);
    //     sessionStorage.setItem("roleId", res.data.data.roleId);
    //     sessionStorage.setItem("userId", res.data.data.id);
    //     const data = await this.$http({
    //       method: "get",
    //       url: `front/company/findList?companyUserId=${sessionStorage.getItem(
    //         "userId"
    //       )}`,
    //     });
    //     if (data.data.status == 200) {
    //       if (data.data.data.list.length) {
    //         sessionStorage.setItem("companyId", data.data.data.list[0].id);
    //       }
    //     }
    //   } else {
    //     sessionStorage.setItem("roleId", "null");
    //   }
    // },
    //去往购物清单
    shopList() {
      const token = sessionStorage.getItem("token");
      if (token) {
        this.$router.push("shoppingList");
      } else {
        this.$message("请登录");
      }
    },
    // 去往购物车
    goCar() {
      const token = sessionStorage.getItem("token");
      if (token) {
        this.$router.push("goodCar");
      } else {
        this.$message("请登录");
      }
    },
    // 根据积分商城id查询详细信息
    async goScorecard(id) {
      console.log(id);
      const res = await this.$http({
        method: "get",
        url: `front/integralMall/findIntegralMallById?id=${id}`,
      });
      if (res.data.data) {
        console.log(1);
      }
    },
    // 分类列表显示隐藏
    hidechildListKey(k) {
      this.catechildListKey = k;
      this.cateboxshow = true;
    },
    // 分类列表显示隐藏
    leave() {
      this.cateboxshow = false;
    },
    // 登录
    toLogin() {
      this.$store.commit("updateshowlogin", true);
      
    },
    // 页面回退强制刷新
    // aa() {
    //   if (window.name != "bencalie") {
    //     this.reload();
    //     window.name = "bencalie";
    //     console.log("bencalie==================================");
    //   } else {
    //     window.name = "";
    //     console.log("'==================================");
    //   }
    // },
    //我的订单
    myOrder() {
      const token = sessionStorage.getItem("token");
      console.log(token);
      if (token) {
        this.$router.push("orderList");
      } else {
        this.$message("请登录");
      }
    },
    purchaseOrder() {
      const token = sessionStorage.getItem("token");
      console.log(token);
      if (token) {
        this.$router.push("purchaseOrderList");
      } else {
        this.$message("请登录");
      }
    },
    IncUpdate(){
      const token = sessionStorage.getItem("token");
      console.log(token);
      if (token) {
        this.$router.push("IncUpdate");
      } else {
        this.$message("请登录");
      }
    },
    applyFor(){
      const token = sessionStorage.getItem("token");
      console.log(token);
      if (token) {
        this.$router.push("applyFor");
      } else {
        this.$message("请登录");
      }
    },
    
    // 去往个人中心
    myCenter() {
      this.$router.push("myCenter");
    },
    // 积分详情
    async getScorecard() {
      if (sessionStorage.getItem("roleId") != "null") {
        const res = await this.$http({
          method: "get",
          url: `front/integralMall/findAllIntegralMall?pageNum=1&pageSize=2`,
        });
        if (res.data.data) {
          this.scorecardList = res.data.data.list;
        }
      }
    },
    handleScroll() {
      let scrollTop = this.$parent.$el.scrollTop; //表示获取router-view上一级的scrollTop

      if (scrollTop > 250) {
        this.showheader = true;
      } else {
        this.showheader = false;
      }
    },
    ...mapMutations(["changeLogin"]),
    async goLogin(){
      const res = await this.$http({
        method: "get",
        url: "/front/user/getUserByToken",
      });
      console.log(res,"======")
      if(res.data.data){
        sessionStorage.setItem("userinfo", JSON.stringify(res.data.data));
        sessionStorage.setItem("roleId", res.data.data.roleId);
        sessionStorage.setItem("userId", res.data.data.id);
      }
      this.userinfo = JSON.parse(sessionStorage.getItem("userinfo"));
    },
    //微信扫码登录
    async wxlogIn(){
      console.log("路径打印222",window.location.href)
      let code =window.location.href.split('?')[1];
      code = code.substring(5,code.indexOf('&'));
      let wechatcode = code;
      console.log(wechatcode)
      const res = await this.$http({
        method:'get',
        url:`front/user/wxScanLogin?code=${wechatcode}`,
      });
        if(res.data.status == 200){
        this.$store.commit("updateshowlogin", false);
         this.$message({
          message: "登录成功",
          type: "success",
        });
      console.log(res.data.data,"sdsdsdsaasd");
      sessionStorage.setItem("token",res.data.data);
      this.goLogin();
      
      }
    },
    
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll, true);
  },
  created() {
    this.getScorecard();
    this.getgategoryList();
    this.getswiperList();
    this.getgoodList();
    this.goCompanyList();
    this.getshopnum();
    this.getshopnums();
    this.getbusiness();
    this.findBusinessByUserId();
    let add = JSON.parse(sessionStorage.getItem("userinfo"));
    if(add == null){
      this.wxlogIn();
    }
    // this.goGoodList();
  },
  components: {
    navMenu,
    myWallBulletin,
    myWallBulletins,
    myWallBulletinss,
    cityCascade,
  },
};
</script>


<style lang="scss" scoped>
.talk {
  min-width: 220px;
  width: 220px;
  margin-right: 5%;
  line-height: 95px;
  cursor: pointer;
  span {
    font-size: 16px;
  }
  .el-icon-chat-dot-round {
    font-size: 25px;
  }
}

.el-button {
  padding: 12px 20px;
}
#el-main {
  width: 1229px;
  min-width: 1229px;
  min-height: 700px;
  padding: 0 0;
  position: relative;
  /* margin-top: 160px; */
  overflow-x: hidden;
  margin: 0 auto;
  overflow-y: hidden;
  .select-tab {
    position: relative;
    top: 500px;
    margin: 15px auto;
    width: 100%;
    & > div:nth-child(1) {
      display: flex;
      flex-direction: row;
      & > div {
        flex: 1;
        cursor: pointer;
        padding: 5px;
        & > div {
          padding: 5px;
          border-radius: 6px;
          background: #fff;
          p {
            padding: 8px;
            padding-bottom: 12px;
            font-size: 18px;
            font-weight: 700;
            color: #333;
          }
          img {
            width: 100%;
            height: 120px;
          }
        }
      }
    }
    // & > div:nth-child(2) {
    //   div {
    //     margin-top: 10px;
    //     padding: 10px 20px;
    //     box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    //     border-radius: 6px;
    //     background: #fff;
    //     display: flex;
    //     flex-wrap: wrap;
    //     z-index: 1000;
    //   }
    // }
  }
}
* {
  margin: 0;
  padding: 0;
  /* width: 100%; */
}
body {
  width: 100%;
  
}

#el-container {
  background-color: #f4f4f4;
  /* height: 8000px; */
}
.Headeradvertising {
  width: 100%;
  height: 80px;

  position: relative;
  background-color: rgb(235, 161, 173);
  img {
    display: block;
    height: 100%;
    margin: 0 auto;
  }
  i {
    cursor: pointer;
    font-weight: 700;
    font-size: 18px;
    color: #fff;
    background: #2d2d2d;
    width: 21px;
    height: 21px;
    text-align: center;
    line-height: 21px;
    opacity: 0.3;
    position: absolute;
    top: 5px;
    right: 170px;
  }
}
#header {
  padding: 0;
  display: inline-block;
  justify-content: space-around;
  width: 100%;
  z-index: 1000;
  background-color: white;
}

.header-box {
  width: 100%;
  height: 30px;
  min-width: 1000px;
  background-color: #e3e4e5;
}
.header-box-left {
  z-index: 8;
  display: flex;
  font-size: 10px;
  letter-spacing: 1px;
  line-height: 30px;
  margin-left: 13%;
  width: 100%;
  position: relative;
  & > div:nth-child(1) {
    user-select: none;
    cursor: pointer;
    margin-right: 100px;
    & > div:nth-child(1) {
      i {
        color: #f10215;
        font-size: 16px;
        position: relative;
        top: 2px;
      }
    }
  }
  & > div:nth-child(2) {
    user-select: none;
  }
}
.header-box-login {
  margin-left: 30%;
  cursor: pointer;
  letter-spacing: 1px;
}
.header-box-register {
  margin-left: 2%;
  color: #e33333;
  cursor: pointer;
  letter-spacing: 1px;
}
.header-box-list {
  margin-left: 2%;
  /* color: #e33333; */
  cursor: pointer;
}
.header-search {
  display: flex;
  justify-content: space-between;
  flex: 1;
}
.avater-logo {
  width: 200px;
  height: 100px;
  padding-left: 7%;
  cursor: pointer;
}
.search-box {
  width: 100%;
  height: 10px;
  display: flex;
  margin-right: 4%;
  /* background-color: red; */
}
.el-input {
  width: 81%;
}
.search-box-main {
  display: flex;
  /* width: 30%; */
  margin: auto;
  margin-top: 32px;
}
.search-input {
  width: 500px;
  height: 35px;
  border-right: 0;
  border-radius: 2px;
  position: relative;
  z-index: 5;
}
.input {
  display: block;
  width: 87%;
  height: 100%;
  position: absolute;
  border: 2px solid #e1251b;
  right: 12.5%;
  z-index: 3;
  text-indent: 10px;
}
.input:focus {
  outline: none;
  /* border: 1px solid red; */
}
.go-search {
  border: 2px solid #e1251b;
  width: 13%;
  height: 100%;
  background-color: #e1251b;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}
.search-icon {
  display: block;
  width: 18px;
  height: 19px;
  margin-left: 38%;
  margin-top: 12%;
}
.header-search .shop-car,.header-search .shop-list {
  min-width: 120px;
  display: flex;
  width: 250px !important;
  height: 32px;
  border: 1px solid #e3e4e5;
  margin: auto;
  margin-right: 4%;
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;
}
.header-search .shop-list span{
  color: rgb(219, 6, 6);
}
.header-search .shop-car span {
  padding-left: 5px;
}

.shopcar-img {
  width: 28px;
  height: 28px;
  margin-top: 2px;
  margin-left: 3px;
}
.shop-car p,.shop-list p {
  display: block;
  font-size: 14px;
  line-height: 35px;
  margin-left: 10px;
  overflow: hidden;
}
.shop-car div {
  width: 30px;
  height: 20px;
  border-radius: 10px;
  background-color: rgb(255, 145, 0);
  margin-top: 7px;
  margin-left: 3px;
}
.shop-cara p {
  font-size: 14px;
  margin: auto;
  text-align: center;
  line-height: 20px;
  font-weight: 800;
  color: white;
}

.good {
  width: 100%;
  /* height: 2000px; */
}
.swiper-box {
  /* margin: auto; */
  /* max-width: 800px; */
  width: 840px;
  height: 400px;
  /* background-color: #e33333; */
  position: absolute;
  /* min-width: 600px; */
  left: 189px;
  top: 0px;
  z-index: 1;
}
.swiper-img {
  width: 100%;
  height: 100%;
}
.cate-list {
  /* min-width: 500px; */
  width: 189px;
  height: 500px;
  background-color: #4b494e;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  /* z-index: 1000; */
  /* border-top-left-radius: 6px; */
  border-bottom-left-radius: 6px;
}
.cate-list div {
  width: 100%;
  height: 30px;
  /* background-color:blue; */
  /* border: 1px solid black; */
}
.cate-list div:nth-child(1) {
  margin-top: 10px;
}
.cate-list-div p {
  line-height: 30px;
  color: #fff;
  padding-left: 10px;
  margin-left: 15px;
  font-size: 14px;
  cursor: pointer;
}
.cate-list-div p::after {
  content: "";
  display: inline-block;
  border-top: 1px solid #f5f5f5;
  border-right: 1px solid #f5f5f5;
  width: 6px;
  height: 6px;
  margin-bottom: 2px;
  margin-left: 65px;
  transform: rotate(45deg);
}
.cate-list-div:hover {
  background-color: #ff6700;
  /* display: block; */
}
.cate-list > .cate-list-div > p:hover {
  color: #fff;
}
.none-box {
  clear: both;
  /* float: left; */
  width: 650px;
  height: 480px;
  opacity: 0.9;
  padding-top: 20px;
  background-color: white;
  /* border: 5px solid #412828; */
  position: absolute;
  top: 0px;
  left: 189px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  z-index: 2;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  // box-shadow: 1px 1px 0.5px 1px #e3e4e5;
}
.cate-list-div-span {
  padding: 20px 0;
  flex: 20%;
  display: flex;
  height: 25px;
  cursor: pointer;
}
.cate-span-list {
  font-size: 12px;
  line-height: 30px;
  display: flex;
  list-style: none;
  margin-left: 7px;
}
.cate-div-txt {
  line-height: 20px;
  font-size: 12px;
  font-weight: 600;
  margin-left: 20px;
}
.catego-img-two {
  margin-top: 5px;
  margin-left: 7px;
  width: 10px;
  height: 10px;
}
.cate-span-list li {
  padding-left: 10px;
}
/* .cate-list-div:hover .none-box{
   display: block;
} */
.el-main-box {
  /* display: flex; */
  z-index: 2;
  margin: 0 auto;
}
.el-main p:hover {
  color: #ff6700;
}

.login-right-box {
  width: 200px;
  height: 500px;
  background-color: #4b494e;
  display: inline-block;
  position: absolute;
  left: 1029px;
  top: 0px;
  /* border-top-right-radius: 6px; */
  border-bottom-right-radius: 6px;
  overflow-y:auto
}
.block {
  display: flex;
  margin-left: 20px;
  margin-top: 20px;
}
.block-txt {
  /* display: flex; */
  font-size: 12px;
  margin-top: 2px;
  margin-left: 5px;
  /* padding: 5px; */
}
.block-txt-title {
  user-select: none;
  color: #fff;
}
.block-txt span {
  padding-left: 5px;
  line-height: 15px;
}
.block-txt-a,
.blcok-txt-b {
  color: #fff;
}
.block-txt-a:hover {
  color: #f9690c;
  cursor: pointer;
}

.actar-div {
  width: 140px;
  height: 0.3px;
  background-color: black;
  margin: auto;
  margin-top: 10px;
}
.fest-message {
  display: flex;
  justify-content: space-between;
  padding: 15px;
}

.fest-message-more-box,
.fest-message-more {
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  color: #dfdfdf;
}
.fest-message-txt {
  font-size: 14px;
  font-weight: 400;
  user-select: none;
  color: #dfdfdf;
}
.fest-message-more:hover {
  color: #f95e40;
}
.fest-message-more-box:hover .el-icon-arrow-right {
  color: #f95e40;
}
.el-icon-arrow-right {
  color: #dfdfdf;
}

.goods-main {
  width: 100%;
  min-width: 1000px;
  position: relative;
  margin-top: 100px;
  & > span:nth-child(1) {
    position: absolute;
    top: 40px;
    right: 170px;
    color: #888;
    font-size: 16px;
    cursor: pointer;
    i {
      color: #888;
    }
  }
  /* height: 1000px; */
  /* background-color: #e33333; */
}
.goods-main-message {
  display: flex;
  margin-top: 20px;
  margin: 20px;
  margin-left: 43%;
}
.goods-main-img {
  width: 50px;
  height: 50px;
  /* margin-top: 20px; */
}
.goods-main-txt {
  font-size: 24px;
  font-weight: 700;
  margin: 10px 0;
}
.goods-list {
  position: relative;
  width: 83.5%;
  height: auto;
  /* background-color: aqua; */
  /* margin: auto; */
  display: flex;
  justify-content: space-around;
  margin-left: 8%;
  flex-wrap: wrap;
}
.goods-list-box {
  box-sizing: border-box;
  width: 19%;
  height: 300px;
  background-color: white;
  margin-top: 10px;
  position: relative;
  border-radius: 6px;
  transition: all 0.2s linear;
}

.goods-list-box:hover {
  // box-shadow: 1px 5px 10px #a4a4a4;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  margin-top: 7px;
}

.goods-list-box-img {
  width: 65%;
  height: 140px;
  margin: 18%;
  margin-top: 19%;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s linear;
  // transform: scale(1);
}
.goods-list-box-img:hover {
  transform: scale(1.05);
}
.goods-message-txt {
  width: 100%;
  height: 68px;
  /* background-color:pink; */
  position: absolute;
  bottom: 15px;
  left: 0;
}
.goods-message-title {
  letter-spacing: 1px;
  line-height: 20px;
  display: block;
  width: 80%;
  height: 39%;
  margin: auto;
  font-size: 14px;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  margin-top: 10px;
}
.goods-price {
  margin-left: 19px;
  margin-top: 3px;
}
.price-a {
  font-size: 8px;
  color: #e33333;
}
.price-b {
  font-size: 16px;
  font-weight: 600;
  color: #e33333;
}
.scorecard-box {
  box-sizing: border-box;
  width: 100%;
  padding: 5px;
  position: relative;
  cursor: pointer;
}

.scorecard-box-item div {
  display: inline-block;
  width: 70px;
  font-size: 12px;
  position: absolute;
  left: 60px;
  top: 25px;
  overflow: hidden;
}
.scorecard-box-item > div > span {
  color: #333;
}
.scorecard-box-item img {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
.scorecard-box-item > span {
  color: #333;
  font-size: 12px;
  position: absolute;
  left: 135px;
  top: 25px;
}
.nav-menu {
  margin: 0 auto;
  margin-top: 40px;
  width: 1229px;
}
.scorecard-box-box-card-box {
  display: flex;
  padding: 2px 0px;
}
.scorecard-box-box-card-box div:nth-child(1) {
  width: 50px;
  text-align: center;
  background: #fdeeed;
  margin-right: 5px;
  padding: 2px;
}
.scorecard-box-box-card-box div:nth-child(1) span {
  color: #e1251b;
  font-size: 14px;
}
.scorecard-box-box-card-box div:nth-child(2) {
  width: 100px;
  padding: 2px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}
.scorecard-box-box-card-box div:nth-child(2) span {
  color: #666;
  font-size: 14px;
}
.hide {
  & ::v-deep .el-header {
    top: -150px !important;
    transition: top 0.1s linear;
  }
}
.show {
  & ::v-deep .el-header {
    top: 0px !important;
    transition: top 0.3s linear;
  }
}
.rightdvertising {
  width: 150px;
  height: 200px;
  position: fixed;
  top: 50%;
  right: 19px;
  margin-top: -100px;
  z-index: 1000000;
  img {
    width: 100%;
    height: 100%;
  }
  i {
    cursor: pointer;
    font-weight: 700;
    font-size: 18px;
    color: #fff;
    background: #2d2d2d;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    opacity: 0.5;
    position: absolute;
    top: 5px;
    right: 5px;
  }
}
</style>
